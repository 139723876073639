import React from "react";
import { Status } from "./Status";
import { StatusPageResponse } from "./types";

type Props = React.HtmlHTMLAttributes<HTMLHtmlElement> & {
  statusPageId: string;
};

type State = {
  loading: boolean;
  statusPageResponse: StatusPageResponse | null;
  prevId?: string;
};

export default class OperationalStatus extends React.Component<Props, State> {
  _isMounted = false;

  state: State = {
    loading: true,
    statusPageResponse: null
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    // Store prevId in state so we can compare when props change.
    // Clear out previously-loaded data (so we don't render stale stuff).
    if (nextProps.statusPageId !== prevState.prevId) {
      return {
        statusPageResponse: null,
        prevId: nextProps.statusPageId
      };
    }

    // No state update necessary
    return null;
  }

  componentDidMount() {
    this._isMounted = true;
    this._loadAsyncData();
  }

  componentWillUnmount() {
    // Prevent potential setState calls after unmount,
    // (Since these trigger DEV warnigs)
    this._isMounted = false;
  }

  render() {
    const { statusPageResponse, loading } = this.state;
    if (loading || !statusPageResponse) {
      return null;
    }

    return <Status statusPageResponse={statusPageResponse} {...this.props} />;
  }

  _loadAsyncData() {
    fetch(`https://${this.props.statusPageId}.statuspage.io/api/v2/status.json`)
      .then(response => {
        if (response.status >= 400) {
          throw new Error("Bad response from server");
        }
        return response.json();
      })
      .then((statusPageResponse: StatusPageResponse) => {
        // Only update state if the Promise that has just resolved,
        // Reflects the most recently requested external data.
        if (this._isMounted) {
          this.setState({ statusPageResponse, loading: false });
        }
      });
  }
}
