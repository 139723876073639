import React from "react";
import { Box, Flex } from "@urbaninfrastructure/react-ui-kit";
import { theme, neutral } from "@urbaninfrastructure/design-tokens";
import { Anchor } from "../Anchor";
import { StatusPageResponse } from "./types";

type Props = {
  statusPageResponse: StatusPageResponse;
  render?: (response: StatusPageResponse) => React.ReactNode;
};

const colorMap = {
  none: neutral.gray05,
  minor: "rgb(250,210,1)", // RAL 1023
  major: "rgb(237,118,14)", // RAL 1037
  critical: theme.red01.hex
};

const DOT_RADIUS = 16;

export const Status = (props: Props) => {
  const {
    statusPageResponse: {
      page: { url },
      status: { description, indicator }
    },
    render,
    ...rest
  } = props;
  return (
    <Anchor href={url} display="block" {...rest}>
      <Flex alignItems="center" justifyContent="center">
        <Box
          as="span"
          display="inline-block"
          m={1}
          mr={3}
          width={DOT_RADIUS}
          height={DOT_RADIUS}
          bg={colorMap[indicator]}
          borderRadius="50%"
        />
        {render && render(props.statusPageResponse)}
        {!render && description}
      </Flex>
    </Anchor>
  );
};

export default Status;
